import React, {useMemo, useState} from 'react';
import {Container} from "./styled";
import {EmailTemplatesRow, TemplatesHeader, TemplatesInner} from "../../styled";
import {Button, Link, NoData, WithPermissions, ViberStatisticsModal, ViewViberTemplateModal} from "@components";
import {Paths, UserPermissions} from "@constants";
import LargeTemplateItem from "../../../../../../components/ui/LargeTemplateItem/LargeTemplateItem";
import {RioService} from "../../../../../../services";
import {archiveViberTemplate, getSingleViber, getViberTemplates} from "@store/actions/creators";
import { useNavigate } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useModalState, useTranslation} from "@hooks";
import {templateViberSelector} from "@store/selectors";
import {notify} from "@utils";

const ViberSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewViberModal = useModalState();
  const { p, t } = useTranslation('dashboard_page');
  const [statisticViberId, setViberStatisticsId] = useState(null);
  const statisticsViberModal = useModalState();
  const viberTemplates = useSelector(templateViberSelector);
  const [viberViewItem, setViberViewItem] = useState({});

  const shouldRender = useMemo(() => {
    return !!viberTemplates.data?.filter(t => t.status !== 3)?.length;
  }, [viberTemplates.data])

  const handleCreateViberTemplateClick = () => {
    navigate(`${Paths.TEMPLATES_VIBER}/new?f=default`);
    RioService.track('templates_dash');
  }

  const handleViewViber = (data) => {
    setViberViewItem(data);
    viewViberModal.open();
  }

  const handleEditViber = (id, used) => {
    if (used !== 1) {
      navigate(`${Paths.TEMPLATES_VIBER}/${id}?view=1&f=default`)
    }
  }

  const handleDuplicateViber = (id) => {
    dispatch(getSingleViber({ id }));
    navigate(`${Paths.TEMPLATES_VIBER}/duplicate?f=default`)
  }

  const deleteViberMenuHandler = (id, workers, name) => {

  }

  const handleViberArchive = (id, name) => {
    dispatch(archiveViberTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.viber_template_archived'), name);
        dispatch(getViberTemplates({ page: 1, perPage: 6, all: true }));
      },
    }));
  }

  const handleViberStatisticsClick = (id) => {
    setViberStatisticsId(id);
    statisticsViberModal.open();
  };

  const handleCloseViberEmailModal = () => {
    setViberViewItem({});
    viewViberModal.close();
  };

  const handleViberModalAction = () => {
    handleEditViber(viberViewItem.id);
  };

  return (
    <Container data-testid="dashboard-templates-section-viber">
      <TemplatesInner style={{ marginTop: 10, paddingBottom: 0, pointerEvents: !!viberTemplates?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
        <TemplatesHeader>
          <Link to={`${Paths.TEMPLATES_VIBERS}/default`} icon>{p('viber')}</Link>
          <WithPermissions name={UserPermissions.CREATE_VIBER}>
            <Button
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px'
              }}
              testId="dashboard-templates-section-viber-create"
              width="none"
              onClick={() => handleCreateViberTemplateClick(true)}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </TemplatesHeader>
        <EmailTemplatesRow $empty={!(!!viberTemplates.data?.filter(t => t.status !== 3)?.length)}>
          {!(!!viberTemplates.data?.filter(t => t.status !== 3)?.length) && <NoData style={{ height: 'auto', width: 'auto' }} />}
          {viberTemplates.data?.filter(t => t.status !== 3)?.slice(0, 4).map(({
            id,
            name,
            created_at,
            languages,
            status,
            workflows,
            workers,
            used,
          }, i) => {
            const defaultLang = languages?.[0];
            return (
              <LargeTemplateItem
              key={id}
              type="viber"
              used={used}
              testId={`viber-templates-item-${i}`}
              status={status}
              image_url={defaultLang.image?.url}
              name={name}
              title={name}
              btn_name={defaultLang.btn_name}
              date={created_at}
              body={defaultLang.data}
              fromDashboard
              onArchive={() => handleViberArchive(id, name)}
              onStatisticsClick={() => handleViberStatisticsClick(id)}
              onViewClick={() => handleViewViber({ id, name, data: defaultLang.data, image_url: defaultLang.image?.url, btn_name: defaultLang.btn_name, workflows: workers })}
              onEditClick={() => handleEditViber(id, workers?.used)}
              onDuplicateClick={() => handleDuplicateViber(id)}
              onDeleteClick={() => deleteViberMenuHandler(id, workers, name)}
            />
            )
          })}
        </EmailTemplatesRow>
      </TemplatesInner>
      <ViewViberTemplateModal
        opened={viewViberModal.opened}
        onClose={handleCloseViberEmailModal}
        onActionClick={handleViberModalAction}
        data={viberViewItem}
      />
      <ViberStatisticsModal id={statisticViberId} opened={statisticsViberModal.opened} onClose={statisticsViberModal.close} />
    </Container>
  )
}

export default React.memo(ViberSection);
