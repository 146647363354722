import { all, takeEvery, takeLatest, put } from 'redux-saga/effects';
import { TemplatesActionTypes } from '../../actions/types';
import { Endpoints } from "@constants";
import { mapEntries } from "@utils";
import { createRequestAction } from "@store/lib/utils/creators";

function* archiveApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/webhook/archive',
      body: { id: action.payload.id },
    })
  );
}

function* loadTemlatesLinksSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_LINKS_TEMPLATE_EMAIL,
      body: {
        links: action.payload.links,
      },
    })
  );
}

function* archiveEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/email/archive',
      body: { id: action.payload.id },
    })
  );
}

function* archiveMobilePushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/mobile_push/archive',
      body: { id: action.payload.id },
    })
  );
}

function* archiveSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/sms/archive',
      body: { id: action.payload.id },
    })
  );
}

function* createViberTemplateSaga(action) {
  const formData = new FormData();
  formData.append('name', action.payload.name);
  formData.append('app_integration_id', action.payload.app_integration_id);

  action.payload.languages.forEach((lagItem, index) => {
    Object.keys(lagItem).forEach(key => {
      if(!lagItem[key]) {
        return;
      }

      formData.append(`languages[${index}][${key}]`, lagItem[key]);
    });
  });

  formData.append(`meta[viber_fields][]`,action.payload.meta.viber_fields);
  
  if (action.payload.viber_fields) {
    for (let i = 0; i < action.payload.viber_fields.length; i++) {
      formData.append('viber_fields[]', action.payload.viber_fields[i]);
    }
  }

  if (action.payload.links) {
    action.payload.links.forEach((link, index) => {
      Object.keys(link).forEach(key => {
        if(key === 'url') {
          formData.append(`links[${index}][${key}]`, link[key]);
        } else {
          formData.append(`links[${index}][${key}]`, link[key] ? 1 : 0);
        }
      });
    });
  }

  if (action.payload.folder_id) {
    formData.append('folder_id', action.payload.folder_id);
  }

  if (action.payload.id) {
    formData.append('id', action.payload.id);
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_VIBER,
      body: formData,
    })
  );
}

function* createWebpushSaga(action) {
  const formData = new FormData();
  formData.append('name', action.payload.body.name);
  formData.append('title', action.payload.body.title);
  formData.append('body', action.payload.body.body);
  formData.append('ttl', action.payload.body.ttl);
  formData.append('date', action.payload.body.date);
  formData.append('app_integration_id', action.payload.body.app_integration_id);

  if (action.payload.body.folder_id) {
    formData.append('folder_id', action.payload.body.folder_id);
  }

  if (action.payload.body.url) {
    formData.append('url', action.payload.body.url);
  }
  if (action.payload.body.actions) {
    formData.append('actions', action.payload.body.actions);
  }
  if (action.payload.body.id) {
    formData.append('id', action.payload.body.id);
  }
  if (action.payload.body.icon || action.payload.body.icon === '') {
    formData.append('icon', action.payload.body.icon);
  }
  if (action.payload.body.image || action.payload.body.image === '') {
    formData.append('image', action.payload.body.image);
  }
  if (action.payload.body.links) {
    formData.append('links', action.payload.body.links);
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_WEBPUSH,
      body: formData,
    })
  );
}

function* getApiRequestTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_API_REQUEST,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getSmsTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_SMS,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getViberSummarySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.VIBER_SUMMARY,
      params: { template_id: [action.payload.id] },
    })
  );
}

function* sendTestSmsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEND_TEST_SMS,
      body: {
        exampleData: action.payload.exampleData,
        message: action.payload.message,
        fake: action.payload.fake,
        app_integration_id: action.payload.app_integration_id,
        phones: action.payload.phones,
        sms_is: action.payload.sms_id,
      },
    })
  );
}

function* sendTestWebPushSaga(action) {
  const formData = new FormData();
  formData.append('title', action.payload.data.title);
  formData.append('body', action.payload.data.body);
  formData.append('url', action.payload.data.url);
  formData.append('publicKey', action.payload.subscription.publicKey);
  formData.append('authToken', action.payload.subscription.authToken);
  formData.append('endpoint', action.payload.subscription.endpoint);
  formData.append('contentEncoding', action.payload.subscription.contentEncoding);

  if (action.payload.data.icon) {
    formData.append('icon', action.payload.data.icon);
  }
  if (action.payload.data.image) {
    formData.append('image', action.payload.data.image);
  }
  if (action.payload.data.actions) {
    formData.append('actions', action.payload.data.actions);
  }
  if (action.payload.data.id) {
    formData.append('id', action.payload.data.id);
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEND_TEST_WEB_PUSH,
      body: formData,
    })
  );
}

function* validateWebpushTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_WEBPUSH_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* validateWebhookTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_WEBHOOK_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* validateSmsTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_SMS_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* validateEmailTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_EMAIL_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* unarchiveWebpushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/web_push/un_archive',
      body: { id: action.payload.id },
    })
  );
}

function* unarchiveViberTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `${Endpoints.TEMPLATES_VIBER}/un_archive`,
      body: { id: action.payload.id },
    })
  );
}

function* unarchiveSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/sms/un_archive',
      body: { id: action.payload.id },
    })
  );
}

function* unarchiveMobilePushSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/mobile_push/un_archive',
      body: { id: action.payload.id },
    })
  );
}

function* unarchiveEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/email/un_archive',
      body: { id: action.payload.id },
    })
  );
}

function* unarchiveApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/webhook/un_archive',
      body: { id: action.payload.id },
    })
  );
}

function* setViberFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/viber/set/folder',
      body: { id: action.payload.id, folder_id: action.payload.folder_id },
    })
  );
}

function* setFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/folder/set',
      body: { id: action.payload.id, folder_id: action.payload.folder_id, type: action.payload.type },
    })
  );
}

function* setEmailFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/emails/set/folder',
      body: { id: action.payload.id, folder_id: action.payload.folder_id },
    })
  );
}

function* sendTestEmailSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEND_TEST_EMAIL,
      body: {
        app_integration_id: action.payload.app_integration_id,
        subject: action.payload.subject,
        data: action.payload.data,
        emails: action.payload.emails,
        email_id: action.payload.email_id,
        bcc: action.payload.bcc,
        driver: action.payload.driver,
        design: action.payload.design,
        exampleData: action.payload.exampleData,
        fake: action.payload.fake,
      },
    })
  );
}

function* getWebpushTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_WEBPUSH,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getViberTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_VIBER,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getTemplateStatisticsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/notificator/analytics/${action.payload.type}/statuses-summary-count`,
      params: action.payload,
    })
  );
}

function* getSubscriptionGroupsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/subscribe-groups/${action.meta.entity}`
    })
  );
}

function* getSingleViberSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_VIBER(action.payload.id),
    })
  );
}

function* getSingleWebpushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_WEBPUSH_TEMPLATE(action.meta.id),
    })
  );
}

function* getSingleSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_TEMPLATE_SMS(action.meta.id),
    })
  );
}

function* getSingleEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_TEMPLATE_EMAIL(action.payload.id),
    })
  );
}

function* getSingleApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_API_REQUEST_TEMPLATE(action.meta.id),
    })
  );
}

function* getMobilePushTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_MOBILE_PUSH,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getMobilePushSummarySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.MOBILE_PUSH_SUMMARY,
      params: { mobile_push_id: [action.payload.id] },
    })
  );
}

function* getFoldersSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/folder',
      params: { type: action.payload.type },
    })
  );
}

function* getFoldersListSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/folder',
      params: { type: action.payload.type, perPage: 1000 },
    })
  );
}

function* getEmailTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_EMAIL,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getEmailSampleDataSaga(action) {
  const url = action.payload.type === 'email' ? `${Endpoints.GET_EMAIL_SAMPLE_DATA}/?type=email` : Endpoints.GET_EMAIL_SAMPLE_DATA;

  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: url,
    })
  );
}

function* getTemplatePreviewInfoSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_RENDER,
      body: {
        templates: mapEntries({
          ...action.payload.fields
        }, ([, v]) => v !== null, 'filter'),
        customer_id: action.payload.customer_id,
      },
    })
  );
}

function* editSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_SMS,
      body: {
        id: action.payload.id,
        name: action.payload.name,
        languages: action.payload.languages,
        meta: action.payload.meta,
        links: action.payload.links,
        folder_id: action.payload.folder_id,
        app_integration_id: action.payload.app_integration_id,
      },
    })
  );
}

function* editEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_EMAIL,
      body: {
        id: action.payload.id,
        name: action.payload.name,
        app_integration_id: action.payload.app_integration_id,
        languages: action.payload.languages,
        meta: action.payload.meta,
        driver: action.payload.driver,
        links: action.payload.links,
        email_fields: action.payload.email_fields,
        folder_id: action.payload.folder_id,
        group_id: action.payload.group_id,
      },
    })
  );
}

function* deleteWebpushSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.TEMPLATES_WEBPUSH}/delete`,
      body: { id: action.meta.id },
    })
  );
}

function* deleteViberTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_VIBER,
      body: {
        id: action.payload.id,
      },
    })
  );
}

function* deleteSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_TEMPLATE_SMS,
      body: {
        id: action.payload.id,
      },
    })
  );
}

function* deleteMobilePushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_MOBILE_PUSH,
      body: { id: action.payload.id },
    })
  );
}

function* deleteFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: '/api/folder/delete',
      body: { id: action.payload.id, type: action.payload.type },
    })
  );
}

function* deleteEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.DELETE_TEMPLATE_EMAIL}/delete`,
      body: { id: action.payload.id },
    })
  );
}

function* deleteApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.TEMPLATES_API_REQUEST}/delete`,
      body: { id: action.meta.id },
    })
  );
}

function* createSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_SMS,
      body: {
        id: action.payload.id,
        name: action.payload.name,
        languages: action.payload.languages,
        meta: action.payload.meta,
        links: action.payload.links,
        folder_id: action.payload.folder_id,
        app_integration_id: action.payload.app_integration_id,
      },
    })
  );
}

function* createMobilePushSaga(action) {
  const formData = new FormData();
  formData.append('title', action.payload.body.title);
  formData.append('subtitle', action.payload.body.subtitle);
  formData.append('message', action.payload.body.message);
  formData.append('name', action.payload.body.name);
  formData.append('launchLink', action.payload.body.launchLink);
  formData.append('use_external_id', action.payload.body.use_external_id);

  if (action.payload.body.mobile_push_fields) {
    for (let i = 0; i < action.payload.body.mobile_push_fields.length; i++) {
      formData.append('mobile_push_fields[]', action.payload.body.mobile_push_fields[i]);
    }
  }

  formData.append('app_integration_id', action.payload.body.app_integration_id);

  if (action.payload.body.folder_id) {
    formData.append('folder_id', action.payload.body.folder_id);
  }

  if (action.payload.body.id) {
    formData.append('id', action.payload.body.id);
  }
  if (action.payload.body.image || action.payload.body.image === '') {
    formData.append('image', action.payload.body.image);
  }
  if (action.payload.body.links) {
    formData.append('links', action.payload.body.links);
  }
  if (action.payload.body.actions) {
    formData.append('actions', action.payload.body.actions);
  }
  if (action.payload.body.custom_data) {
    formData.append('custom_data', action.payload.body.custom_data);
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_MOBILE_PUSH,
      body: formData,
    })
  );
}

function* createFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/folder',
      body: {
        id: action.payload.id,
        name: action.payload.name,
        type: action.payload.type
      },
    })
  );
}

function* createEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_EMAIL,
      body: {
        name: action.payload.name,
        app_integration_id: action.payload.app_integration_id,
        languages: action.payload.languages,
        meta: action.payload.meta,
        driver: action.payload.driver,
        links: action.payload.links,
        folder_id: action.payload.folder_id,
        email_fields: action.payload.email_fields,
        group_id: action.payload.group_id
      },
    })
  );
}

function* createApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_API_REQUEST,
      body: action.payload.body,
    })
  );
}

function* archiveWebpushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/web_push/archive',
      body: { id: action.payload.id },
    })
  );
}

function* getSingleMobilePushSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_MOBILE_PUSH(action.meta.id),
    })
  );
}

function* archiveViberTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `${Endpoints.TEMPLATES_VIBER}/archive`,
      body: { id: action.payload.id },
    })
  );
}

export default function* templatesRootSaga() {
  yield all([
    takeLatest(TemplatesActionTypes.GET_SMS, getSmsTemplatesSaga),
    takeLatest(TemplatesActionTypes.CREATE_SMS, createSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_WEBPUSH, getWebpushTemplatesSaga),
    takeLatest(TemplatesActionTypes.EDIT_SMS, editSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.DELETE_SMS, deleteSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_SMS, getSingleSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_EMAILS, getEmailTemplatesSaga),
    takeLatest(TemplatesActionTypes.CREATE_EMAIL, createEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_EMAIL_SAMPLE_DATA, getEmailSampleDataSaga),
    takeLatest(TemplatesActionTypes.DELETE_EMAIL, deleteEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_EMAIL, getSingleEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.EDIT_EMAIL, editEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_WEBPUSH, getSingleWebpushTemplateSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_EMAIL_TEMPLATE_NAME, validateEmailTemplateNameSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_SMS_TEMPLATE_NAME, validateSmsTemplateNameSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_WEBPUSH_TEMPLATE_NAME, validateWebpushTemplateNameSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_WEBHOOK_TEMPLATE_NAME, validateWebhookTemplateNameSaga),
    takeLatest(TemplatesActionTypes.CREATE_WEBPUSH, createWebpushSaga),
    takeLatest(TemplatesActionTypes.DELETE_WEBPUSH, deleteWebpushSaga),
    takeLatest(TemplatesActionTypes.SEND_TEST_SMS, sendTestSmsSaga),
    takeLatest(TemplatesActionTypes.SEND_TEST_EMAIL, sendTestEmailSaga),
    takeLatest(TemplatesActionTypes.GET_API_REQUEST, getApiRequestTemplatesSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_API_REQUEST, getSingleApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.CREATE_API_REQUEST, createApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.DELETE_API_REQUEST, deleteApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_TEMPLATE_PREVIEW_INFO, getTemplatePreviewInfoSaga),
    takeLatest(TemplatesActionTypes.SEND_TEST_WEB_PUSH, sendTestWebPushSaga),
    takeLatest(TemplatesActionTypes.GET_SUBSCRIPTION_GROUPS, getSubscriptionGroupsSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_EMAIL_TEMPLATE, archiveEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_SMS_TEMPLATE, archiveSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_WEBPUSH_TEMPLATE, archiveWebpushTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_WEBHOOK_TEMPLATE, archiveApiRequestTemplateSaga),
    takeEvery(TemplatesActionTypes.UNARCHIVE_EMAIL_TEMPLATE, unarchiveEmailTemplateSaga),
    takeEvery(TemplatesActionTypes.UNARCHIVE_SMS_TEMPLATE, unarchiveSmsTemplateSaga),
    takeEvery(TemplatesActionTypes.UNARCHIVE_WEBPUSH_TEMPLATE, unarchiveWebpushTemplateSaga),
    takeLatest(TemplatesActionTypes.UNARCHIVE_WEBHOOK_TEMPLATE, unarchiveApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.UNARCHIVE_VIBER_TEMPLATE, unarchiveViberTemplateSaga),
    takeLatest(TemplatesActionTypes.DELETE_VIBER, deleteViberTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_VIBER_TEMPLATE, archiveViberTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_VIBERS, getViberTemplatesSaga),
    takeLatest(TemplatesActionTypes.GET_VIBER_SUMMARY, getViberSummarySaga),
    takeLatest(TemplatesActionTypes.SET_VIBER_FOLDER, setViberFolderSaga),
    takeLatest(TemplatesActionTypes.SET_FOLDER, setFolderSaga),
    takeLatest(TemplatesActionTypes.SET_SMS_FOLDER, setViberFolderSaga),
    takeLatest(TemplatesActionTypes.CREATE_VIBER, createViberTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_VIBER, getSingleViberSaga),
    takeLatest(TemplatesActionTypes.GET_MOBILE_PUSH_SUMMARY, getMobilePushSummarySaga),
    takeLatest(TemplatesActionTypes.CREATE_FOLDER, createFolderSaga),
    takeLatest(TemplatesActionTypes.GET_MOBILE_PUSH, getMobilePushTemplatesSaga),
    takeLatest(TemplatesActionTypes.DELETE_FOLDER, deleteFolderSaga),
    takeLatest(TemplatesActionTypes.LOAD_TEMPLATES_LINK, loadTemlatesLinksSaga),
    takeLatest(TemplatesActionTypes.SET_EMAIL_FOLDER, setEmailFolderSaga),
    takeLatest(TemplatesActionTypes.CREATE_MOBILE_PUSH, createMobilePushSaga),
    takeLatest(TemplatesActionTypes.GET_FOLDERS, getFoldersSaga),
    takeLatest(TemplatesActionTypes.DELETE_MOBILE_PUSH, deleteMobilePushTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_MOBILE_PUSH_TEMPLATE, archiveMobilePushTemplateSaga),
    takeLatest(TemplatesActionTypes.UNARCHIVE_MOBILE_PUSH_TEMPLATE, unarchiveMobilePushSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_MOBILE_PUSH, getSingleMobilePushSaga),
    takeLatest(TemplatesActionTypes.GET_TEMPLATE_STATISTICS, getTemplateStatisticsSaga),
    takeLatest(TemplatesActionTypes.GET_FOLDERS_LIST, getFoldersListSaga),
  ]);
}
